import CollStatic from "components/Chart/CollStatic";
import { ClassnameByNumberValue } from "Main/Utils";
import { Card, CardBody, CardTitle, Row } from "reactstrap";
import { PriceFormat } from "variables/common";

function CardStats ({ data, userType, currency = 'idr', title}) {
    const { customerNewQty, customerActiveQty,
        customerSuspendQty, customerDisabledQty,
        revenueYoy, revenueMny, customerQty,
        expenseYoy, expenseMny, netRevenueYoy, netRevenueMny,
        mitra } = data

    return (
        <Card className="card-stats card-raised">
              <CardBody>
                {!title ? null : <CardTitle tag="p">{title}</CardTitle>}
                <Row>
                  <CollStatic
                    key = {1}
                    className = {ClassnameByNumberValue(revenueYoy)}
                    value = {PriceFormat(revenueYoy, currency)}
                    title = {"Pendapatan Tahunan"}
                    type = {"icon icon-success"}
                    logo = {"now-ui-icons business_money-coins"}
                  />
                  <CollStatic
                    key = {2}
                    className = {ClassnameByNumberValue(expenseYoy)}
                    value = {PriceFormat(expenseYoy, currency)}
                    title = {"Pengeluaran Tahunan"}
                    type = {"icon icon-primary"}
                    logo = {"now-ui-icons business_money-coins"}
                  />
                  <CollStatic
                    key = {3}
                    className = {ClassnameByNumberValue(netRevenueYoy)}
                    value = {PriceFormat(netRevenueYoy, currency)}
                    title = {"Pendapatan Bersih Tahunan"}
                    type = {"icon icon-warning"}
                    logo = {"now-ui-icons business_money-coins"}
                  />
                  <CollStatic
                    key = {4}
                    value = {userType === 'SUPERADMIN' ? (mitra - 1) : 1}
                    title = {userType === 'SUPERADMIN' ? "Mitra" : "Company"}
                    type = {"icon icon-success"}
                    logo = {"now-ui-icons business_badge"}
                  />
                  <CollStatic
                    key = {5}
                    className = {ClassnameByNumberValue(revenueMny)}
                    value = {PriceFormat(revenueMny, currency)}
                    title = {"Pendapatan Bulanan"}
                    type = {"icon icon-success"}
                    logo = {"now-ui-icons business_money-coins"}
                  />
                  <CollStatic
                    key = {6}
                    className = {ClassnameByNumberValue(expenseMny)}
                    value = {PriceFormat(expenseMny, currency)}
                    title = {"Pengeluaran Bulanan"}
                    type = {"icon icon-primary"}
                    logo = {"now-ui-icons business_money-coins"}
                  />
                  <CollStatic
                    key = {7}
                    className = {ClassnameByNumberValue(netRevenueMny)}
                    value = {PriceFormat(netRevenueMny, currency)}
                    title = {"Pendapatan Bersih Bulanan"}
                    type = {"icon icon-warning"}
                    logo = {"now-ui-icons business_money-coins"}
                  />
                  <CollStatic
                    key = {8}
                    value = {customerQty}
                    title = {"Total Customer"}
                    type = {"icon icon-warning"}
                    logo = {"now-ui-icons users_single-02"}
                  />
                  <CollStatic
                    key = {9}
                    value = {customerNewQty}
                    title = {"Pelanggan Menunggu Aktivasi"}
                    type = {"icon icon-success"}
                    logo = {"now-ui-icons users_single-02"}
                  />
                  <CollStatic
                    key = {9}
                    value = {customerActiveQty}
                    title = {"Pelanggan Aktif"}
                    type = {"icon icon-success"}
                    logo = {"now-ui-icons users_single-02"}
                  />
                  <CollStatic
                    key = {10}
                    value = {customerSuspendQty}
                    title = {"Pelanggan Suspend"}
                    type = {"icon icon-danger"}
                    logo = {"now-ui-icons users_single-02"}
                  />
                  <CollStatic
                    key = {10}
                    value = {customerDisabledQty}
                    title = {"Pelanggam Non-Aktif"}
                    type = {"icon icon-danger"}
                    logo = {"now-ui-icons users_single-02"}
                  />
                </Row>
              </CardBody>
            </Card>
    )
}

export default CardStats