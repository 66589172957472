import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Row,
  Col,
} from "reactstrap";

// core components
import { getDashboard } from "variables/common";
import CustomerService from "Main/CustomerService";
import { addDashboard } from "variables/common";
import { getUserData } from "variables/common";
import DashboardChart from "components/Chart/DashboardChart";
import SellingTable from "components/Table/SellingTable";
import CardStats from "components/Table/CardStats";
import { verifySuperAdmin } from "Main/Utils";

function Dashboard() {
  const userData = getUserData()
  const userType = userData.userType
  const [dashData, dashDataOnChange] = useState(getDashboard())
  const customerService = new CustomerService()
  const { revenueStats, topSelling, realizeSell } = dashData
  const bestSelling = topSelling.data
  const realizeSellData = realizeSell.data
  const realizeTotal = realizeSell.total
  const currency = topSelling.currency
  const totalPrc = topSelling.total
  const { mitraSuper } = verifySuperAdmin()

  const fetch = async() => {
    const dashboard = await customerService.loadDashboard()
    const Data = dashboard.data
    addDashboard(Data)
    dashDataOnChange(Data)

    if (!Data) {
      window.location.reload()
    }
  }
  useEffect(() => {
    fetch()
  }, [])


  return (
    <>
      <DashboardChart
        data = {revenueStats}
      />
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <CardStats
              key={'1qwdqwdqwsw'}
              title = {!mitraSuper ? "Quick Access Report" : "Quick Access Report-Semua Mitra"}
              data = {dashData}
              currency = {currency}
              userType = {userType}
            />
          </Col>
        </Row>
        <SellingTable
          title = "List 5 Produk Terpopuler"
          key={'2qwdqwdqw'}
          data= {bestSelling}
          total = {totalPrc}
        />
        <SellingTable
          key={'3sdwedwe'}
          data = {realizeSellData}
          total = {realizeTotal}
          title = "List Penjualan Terkini"
        />
      </div>
    </>
  );
}

export default Dashboard;
