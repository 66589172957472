export const Columns = [
    {
        Header: "Host",
        accessor: "host",
        maxWidth: 150,
        width: 150
    },
    {
        Header: "Pengirim",
        accessor: "sender",
        maxWidth: 250,
        width: 250
    },
        {
        Header: "Tujuan",
        accessor: "destination",
        maxWidth: 250,
        width: 250
    },
    {
        Header: "Status",
        accessor: "status",
        maxWidth: 100,
        width: 100
    },
        {
        Header: "Tanggal",
        accessor: "creation",
        maxWidth: 250,
        width: 250
    },
    {
        Header: "View",
        accessor: "actions",
        maxWidth: 50,
        width: 50,
        sortable: false,
        filterable: false,
    }
]