import PanelHeader from "components/PanelHeader/PanelHeader"
import { Line } from "react-chartjs-2"
import { dashboardPanelChart } from "variables/charts"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { MainChartDataProcessing } from "Main/Utils";
import { Card } from "reactstrap";

function DashboardChart ({data, size, legend = true, chartLine1Label, chartLine2Label, transparantPanel = false, responsive = true, maintainAspectRatio = false, backgroundColor, gridColor, tickColor}) {
    try {
        ChartJS.register(
         CategoryScale,
         LinearScale,
         PointElement,
         LineElement,
         Title,
         Tooltip,
         Legend
        )
        const chartData = MainChartDataProcessing(data)

        const PanelChart = dashboardPanelChart(chartData, chartLine1Label, chartLine2Label, legend, responsive, maintainAspectRatio, backgroundColor, gridColor, tickColor)
        const panelData = PanelChart.data

        if (!transparantPanel) {
            return (
            <PanelHeader
                key={'112e23e2ewedwed'}
                size={size || "lg"}
                content={
                <Line
                    key={'2wqdqwdqwdqw21'}
                    data={panelData}
                    options={PanelChart.options}
                />
                }
              />
            )
        }
        // const { chartIncome } = chartData
        // const panelIndividual = chartsLine1(chartIncome)
        // const { data: charInvdividualData, options: chartIndividualOption } = panelIndividual
        return (
            <Card className="shadow-lg p-2 mt-3 mb-2 bg-dark">
                <div className="panel-body">
                    <Line
                        key={'2hweuwbef'}
                        data={panelData}
                        options={PanelChart.options}
                    />
                </div>
            </Card>
        )
    } catch (error) {
        console.log(error.message)
        return (
            <PanelHeader
                key={'112e23e2ewedwedwedwe'}
                size={size || "lg"}
                content={
                    <p>{error.message}</p>
                }
          />
        )
    }
}

export default DashboardChart