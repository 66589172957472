/*eslint-disable*/
import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  Row,
  Col,
  Button
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import {getRefreshToken} from "variables/common"
import { CatchError } from "Main/Exceptions/exceptions"
import CommonList from "../../../components/Table/CommonList"
import { getUserPermission } from "variables/common";
import MessageLogService from "Main/MessageLog/MessageLogService";
import NoLabelSelect from "components/Input/NoLabelSelect";
import { addMessageLogData } from "variables/MessageLog/MessageLog";
import { customTextFromBoolean } from "Main/Utils";
import { Columns } from "./MessageLogColumns";
import { verifySuperAdmin } from "Main/Utils";
import { getOwner } from "variables/common";
import { DateTimestr } from "Main/Utils";

function MessageLogList(props) {
  const page = 'submission'
  const messageLogService =  new MessageLogService()
  const permission = getUserPermission()
  const Permission = permission.find(data => data.Role.toLowerCase() === page) || ''
  const [load, setDataLoad] = useState(true)
  const [Table, setDataTable] = useState([])
  const refreshToken = getRefreshToken()
  const ownerOption = getOwner()
  const { mitraSuper, organization } = verifySuperAdmin()
  const [periodOption, setPeriodOption] = useState([])
  const [typeOption, setTypeOption] = useState([])
  const [statusOption, setStatusOption] = useState([])
  const defaultStatus = {
    success: 0,
    failed: 0
  }
  const [messageStatus, setMessageStatus] = useState(defaultStatus)
  const [mitra, setMitra] = useState(ownerOption.find((data) => data.value === organization))
  const [period, setPeriod] = useState({})
  const [type, setType] = useState({})
  const [status, setStatus] = useState({})
  try {
    const getAll =  async(owner = mitra.mitraid, period, type, status) => {
      try {
        console.log(owner)
        setDataLoad(true)
        const len = 'ALL'
        const dat =  await messageLogService.getAllData(owner, period, type, status, len)
        const result = dat.data || {}
        const statusState = dat.statusMessage || defaultStatus
        setMessageStatus(statusState)
        setDataTable(result)
        setDataState(mapping(result))
        setDataLoad(false)
        return result
      } catch (error) {
        setDataLoad(false)
        return await CatchError(error, props, refreshToken)
      }
    }

    const Fetch = async () => {
      const typeState = await GetType()
      const statusState = await GetStatus()
      const periodState = await GetPeriod(mitra.mitraid)
      await getAll(mitra.mitraid, periodState.value, typeState.value, statusState.value)
    }

    const GetStatus = async () => {
      const result = await messageLogService.getMessageLogStatus()
      const state = result.data
      setStatusOption(state)
      const states = state.find(sts => sts.value === 1)
      if (!status.value) {
        setStatus(states)
      }
      return states
    }

    const GetType = async () => {
      const result = await messageLogService.getMessageLogType()
      const state = result.data
      setTypeOption(state)
      const states = state.find(sts => sts.value === 'WA')
      if (!type.value) {
        setType(states)
      }
      return states
    }

  const GetPeriod = async (mitra = mitra.mitraid) => {
    const result = await messageLogService.getMessageLogPeriod(mitra)
    const state = result.data
    setPeriodOption(state)
    const states = state[state.length - 1] || {}
    if (!period.value) {
      setPeriod(states)
    }
    return states
  }

  useEffect( () => {
    Fetch()
  }, [])

  const viewData = async(id)=> {
    try {
      const dat = await messageLogService.getData(id)
      const result = dat.data
      addMessageLogData(result)
      props.history.push('/admin/message-log-view')
    } catch (error) {
      return await CatchError(error, props, refreshToken)
    }
  }

  const mapping =  (Table) => {
    return Table.map((Data, i) => {
      try {
          // listState.push({id: Data.id, status: Data.enabled})
          return {
            id: Data.id,
            host: Data.created_by,
            type: Data.msgtype,
            sender: Data.msgFrom,
            destination: Data.msgTo,
            status: customTextFromBoolean((Data.status === 1), 'Terkirim', 'Gagal'),
            creation: DateTimestr(Data.creation),
            actions: (
              // we've added some custom button actions
              <div className="actions-right" key={Data.id}>
                <Button
                  onClick={ async () => {
                    await viewData(Data.id)
                  }}
                  className="btn-icon btn-round"
                  id={`tooltip${Data.id}`}
                  disabled = {!Permission.Read}
                  color="warning"
                  size="sm"
                >
                  <i className="fa fa-edit" />
                </Button>{" "}
              </div>
          ),
        };
        } catch (error) {
          console.log(error)
          return []
        }
      })}

  const [dataState, setDataState] = useState(
    mapping(Table)
  );

  const customMenu = (
    <Row>
      <Col md={4}>
        <NoLabelSelect
          placeholder = {"Mitra"}
          value = {mitra}
          option = {ownerOption}
          isDisabled = {!mitraSuper}
          onChange = {async (e) => {
            setMitra(e)
            await getAll(e.mitraid, period.value, type.value, status.value)
          }}
        />
      </Col>
      <Col md={3}>
        <NoLabelSelect
          placeholder = {"Periode"}
          value = {period}
          option = {periodOption}
          onChange = {async (e) => {
            setPeriod(e)
            await getAll(mitra.mitraid, e.value, type.value, status.value)
          }}
        />
      </Col>
      <Col md={3}>
        <NoLabelSelect
          placeholder = {"Tipe"}
          value = {type}
          option = {typeOption}
          onChange = {async (e) => {
            setType(e)
            await getAll(mitra.mitraid, period.value, e.value, status.value)
        }}
      />
      </Col>
      <Col md={2}>
        <NoLabelSelect
          placeholder = {"Status"}
          value = {status}
          option = {statusOption}
          onChange = {async (e) => {
            setStatus(e)
            await getAll(mitra.mitraid, period.value, type.value, e.value)
        }}
      />
      </Col>
    </Row>
  )

  return (
        <>
        <PanelHeader size="sm" />
          {/* <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Log Pengiriman Pesan</h2>
              </div>
            }
          /> */}
          <div className="content">
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CommonList
                    key={1}
                    header={`Log Pengiriman Pesan - Terkirim ${messageStatus.success} - Gagal ${messageStatus.failed}`}
                    columns={Columns}
                    dataState={dataState}
                    menu = {customMenu}
                    load={load}
                    getData={() => getAll(mitra.mitraid, period.value, type.value, status.value)}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </>
    );
  } catch (error) {
    return (
      <>
      <PanelHeader
            content={
              <div className="header text-center">
                <h2 className="title">List Log Pengiriman Pesan</h2>
              </div>
            }
          />
      <h3>{error.message || error}</h3>
      </>
    )
  }
}

export default MessageLogList;
